export default [
  {
    title: 'Novidades e Promoções',
    links: [
      {
        title: 'Plano de Saúde Pet',
        url: 'https://saude.petlove.com.br'
      },
      {
        title: 'Repet',
        url: '/compra-recorrente'
      },
      {
        title: 'Clube de Descontos',
        url: '/clube-de-descontos'
      },
      {
        title: 'Indique e Ganhe',
        url: 'https://www.petlove.com.br/indique-e-ganhe'
      },
      {
        title: 'Blog Petlove',
        url: 'https://www.petlove.com.br/dicas'
      },
      {
        title: 'Serviços Pet',
        url: '/servicos'
      },
      {
        title: 'Raças de Cachorro',
        url: '/racas/cachorro'
      },
      {
        title: 'Raças de Gatos',
        url: '/racas/gato'
      },
      {
        title: 'Glossário de Doenças',
        url: '/conteudo/saude/doencas'
      },
      {
        title: 'Petlove Já',
        url: '/petlove-ja'
      },
      {
        title: 'Aplicativo Petlove',
        url: '/aplicativo'
      }
    ]
  },
  {
    title: 'Marcas Exclusivas',
    links: [
      {
        title: 'Future Pet',
        url: '/marcas/future-pet'
      },
      {
        title: 'Kroco',
        url: '/marcas/kroco'
      },
      {
        title: 'Liva',
        url: '/marcas/liva'
      },
      {
        title: 'Me.Au Pet',
        url: '/marcas/me-au-pet'
      },
      {
        title: 'Nutrilus',
        url: '/marcas/nutrilus-pro'
      },
      {
        title: 'Nutrilus Prime',
        url: '/marcas/nutrilus-prime'
      },
      {
        title: 'Pidan',
        url: '/marcas/pidan'
      },
      {
        title: 'Reserva',
        url: '/marcas/reserva'
      },
      {
        title: 'Samsung',
        url: '/marcas/samsung'
      },
      {
        title: 'Suprema',
        url: '/marcas/suprema'
      },
      {
        title: 'True',
        url: '/marcas/true'
      },
      {
        title: 'Vitz Pet',
        url: '/marcas/vitz-pet'
      },
      {
        title: 'Ecoa',
        url: '/marcas/ecoa'
      },
      {
        title: 'Papum',
        url: '/marcas/papum'
      }
    ]
  },
  {
    title: 'Categorias',
    links: [
      {
        title: 'Ração para Cachorro',
        url: '/cachorro/racoes'
      },
      {
        title: 'Ração para Gato',
        url: '/gatos/racoes'
      },
      {
        title: 'Areia para Gato',
        url: '/gatos/caixa-de-areia-e-limpeza/areia'
      },
      {
        title: 'Tapete Higiênico para Cachorro',
        url: '/cachorro/higiene-e-limpeza/tapetes-higienicos'
      },
      {
        title: 'Todos os produtos para Antipulgas e Carrapatos',
        url: '/cachorro/medicina-e-saude/antipulgas-e-carrapatos'
      },
      {
        title: 'Osso para Cachorro',
        url: '/cachorro/ossinhos-e-petiscos'
      },
      {
        title: 'Casinha de Cachorro',
        url: '/cachorro/casinhas-e-acessorios/casinhas'
      },
      {
        title: 'Coleira para Gato',
        url: '/gatos/coleiras-e-acessorios/coleiras-e-acessorios'
      },
      {
        title: 'Ração Natural para Gatos',
        url: '/gatos/racoes/racao-natural'
      },
      {
        title: 'Ração Natural para Cachorro',
        url: '/cachorro/racoes/racao-natural'
      },
      {
        title: 'Vermífugo para Gatos',
        url: '/gatos/medicina-e-saude/vermifugos'
      },
      {
        title: 'Caminha para Cachorro',
        url: '/cachorro/caminhas-e-outros/caminhas'
      },
      {
        title: 'Arranhador para Gatos',
        url: '/gatos/caminhas-e-arranhadores/arranhadores'
      },
      {
        title: 'Fonte para Gatos',
        url: '/gatos/acessorios-para-alimentacao/fontes-para-gatos'
      },
      {
        title: 'Coleira Antipulgas',
        url: '/busca?q=coleira%20antipulgas'
      }
    ]
  },
  {
    title: 'Marcas',
    links: [
      {
        title: 'Guabi Natural',
        url: '/marcas/guabi-natural'
      },
      {
        title: 'Simparic',
        url: '/marcas/simparic'
      },
      {
        title: 'Nexgard',
        url: '/marcas/nexgard'
      },
      {
        title: 'Seresto',
        url: '/marcas/seresto'
      },
      {
        title: 'Bravecto',
        url: '/marcas/bravecto'
      },
      {
        title: 'Premier Super Premium',
        url: '/marcas/premier-super-premium'
      },
      {
        title: 'GoldeN',
        url: '/marcas/golden'
      },
      {
        title: 'PremieR',
        url: '/marcas/premier'
      },
      {
        title: 'Royal Canin',
        url: '/marcas/royal-canin'
      },
      {
        title: 'Pedigree',
        url: '/marcas/pedigree'
      },
      {
        title: 'Whiskas',
        url: '/marcas/whiskas'
      },
      {
        title: 'GranPlus',
        url: '/marcas/granplus'
      },
      {
        title: 'Pipicat',
        url: '/marcas/pipicat'
      },
      {
        title: 'Supersecão',
        url: '/marcas/supersecao'
      },
      {
        title: 'Capstar',
        url: '/marcas/capstar'
      },
      {
        title: 'Advocate Gatos',
        url: '/marcas/advocate'
      },
      {
        title: 'Ração Fórmula Natural',
        url: '/marcas/formula-natural'
      },
      {
        title: 'Frontline',
        url: '/marcas/frontline'
      },
      {
        title: 'Revolution',
        url: '/marcas/revolution'
      },
      {
        title: 'Biofresh',
        url: '/marcas/biofresh'
      },
      {
        title: 'Ração N&D',
        url: '/marcas/n-d'
      },
      {
        title: 'Hills',
        url: '/marcas/hills'
      },
      {
        title: 'Churu',
        url: '/marcas/churu'
      }
    ]
  },
  {
    title: 'Ofertas em destaque',
    links: [
      {
        title: 'Nexgard',
        url: '/marcas/nexgard'
      },
      {
        title: 'Simparic',
        url: '/marcas/simparic'
      },
      {
        title: 'Antipulgas e carrapatos',
        url: '/cachorro/medicina-e-saude/antipulgas-e-carrapatos'
      },
      {
        title: 'Apoquel',
        url: '/apoquel-dermatologico-zoetis-para-caes/p'
      },
      {
        title: 'Antibióticos',
        url: '/cachorro/medicina-e-saude/antibioticos'
      }
    ]
  }
];
